import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { BuyWrapper } from './landing2.styles';
import ComparingSection from './comparingSection/ComparingSection';
import PlanSection from './planSection/PlanSection';
import Mockup from './mockup/Mockup';
import WhatYouWillGetSection from './whatYouWillGetSection/WhatYouWillGetSection';
import OpinionsSection from './opinionsSection/OpinionsSection';
import FAQSection from './faq/FAQSection';
import { useMutation } from 'react-query';
import { createLeadHistory } from '@/api/query/lead-history';
import useQueryMemo from '@/common/hooks/urlMemo';
import Fotter from './fotter/Fotter';
import InnovationDesktop from './innovationDesktop/InnovationDesktop';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { EventService } from '@/common/services/eventService';
import { EventNamesEnum } from '@/common/enums/eventNamesEnum';
import { english } from '@/common/data/english';
import HeroSectionTutor from './heroSectionTutor/HeroSectionTutor';
import Header from '@/common/components/Header2';

const LpPage = () => {
  const queryMemo = useQueryMemo();

  const createLeadMutation = useMutation({
    mutationFn: (value) => createLeadHistory(value),
    onSuccess: (data) => {
      localStorage.setItem('lead-history-id', data._id);
    },
  });

  useLayoutEffect(() => {
    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('fbclid') || localStorage.getItem('fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');
    const msclkid = queryMemo.get('msclkid') || localStorage.getItem('msclkid');

    const payload = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
      msclkid: msclkid,
    };

    if (queryMemo.get('utm_campaign')) {
      localStorage.setItem('utm_campaign', queryMemo.get('utm_campaign'));
    }
    if (queryMemo.get('msclkid')) {
      localStorage.setItem('msclkid', queryMemo.get('msclkid'));
    }
    if (queryMemo.get('utm_medium')) {
      localStorage.setItem('utm_medium', queryMemo.get('utm_medium'));
    }
    if (queryMemo.get('utm_source')) {
      localStorage.setItem('utm_source', queryMemo.get('utm_source'));
    }
    if (queryMemo.get('utm_content')) {
      localStorage.setItem('utm_content', queryMemo.get('utm_content'));
    }

    if (queryMemo.get('fbclid')) {
      localStorage.setItem('fbclid', queryMemo.get('fbclid'));
    }

    window.gtag('set', { campaignName: utm_campaign });
    window.gtag('set', { campaignSource: utm_medium });
    window.gtag('set', { campaignMedium: utm_source });
    window.gtag('set', { campaignContent: utm_content });
    window.gtag('set', { campaignFbclid: utm_fbclid });
    window.gtag('set', { campaignMsclid: msclkid });

    createLeadMutation.mutate(payload);
    window.gtag('event', 'page_landing');
  }, []);

  const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);

  useEffect(() => {
    EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
      handleSendIPstats(IP_STATS_ENUM.LANDING),
    );

    EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
      handleFbStats('PageView', {}),
    );

    return;
  }, []);

  return (
    <BuyWrapper>
      <Header />
      <HeroSectionTutor data={english.main.hero} />
      <InnovationDesktop data={english.main.innovation} />
      <ComparingSection data={english.main.compare} />
      <PlanSection data={english.main.planSection} />
      <Mockup data={english.main.mockup} />
      <WhatYouWillGetSection data={english.main.what} />
      <OpinionsSection data={english.main.opinion} />
      <FAQSection data={english.main.faq} />
      <Fotter data={english.footer} />
    </BuyWrapper>
  );
};

export default LpPage;

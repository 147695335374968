import React from 'react';
import styled from 'styled-components';
import LogoTop from '../images/footer/logo-synthi.svg';
import LogoSynthi from '../images/footer/icon-synthi.svg';
import Discord from '../images/footer/discord-icon.svg';
import Insta from '../images/footer/instagram-icon.svg';
import Linkedin from '../images/footer/linkedin.svg';

import LogoTopWhite from '../images/footer/dekstop-logo.svg';
import LogoSynthiWhite from '../images/footer/desktop-logo-full.svg';
import DiscordWhite from '../images/footer/discord-white.svg';
import InstaWhite from '../images/footer/instagram-white.svg';
import LinkedinWhite from '../images/footer/linkedin-white.svg';

import { Link } from 'react-router-dom';

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 600px) {
    border-radius: 40px 40px 0px 0px;
    background: linear-gradient(25deg, #4f17aa 13.62%, #ac02f1 115.35%);
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }
`;

const IconTopWrapper = styled.div``;

const SocialIconsWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin: 32px 0 44px 0;

  @media (min-width: 600px) {
    img {
      margin-top: 10px;
      width: 22px;
    }
  }
`;

const SocialIcons = [Discord, Insta, Linkedin];
const SocialIconsWhite = [DiscordWhite, InstaWhite, LinkedinWhite];

const BottomInfo = styled.div`
  width: calc(100vw - 36px);
  height: 244px;
  background: linear-gradient(25deg, #4f17aa 13.62%, #ac02f1 115.35%);
  display: flex;
  padding: 18px;
  flex-direction: column;

  @media (min-width: 600px) {
    background: unset;
    width: unset;
    justify-content: center;
  }
`;

const CopyRight = styled.div`
  color: #ece5f2;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  @media (min-width: 600px) {
    text-align: left;
    margin-bottom: -15px;
  }
`;

const LinkWrapper = styled(Link)`
  color: #ece5f2;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  text-decoration: none;
`;

const LogoWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    display: none;
  }
`;

const LogoWrapperDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <LogoWrapperMobile>
        <IconTopWrapper>
          <img src={LogoSynthi} />
        </IconTopWrapper>
        <IconTopWrapper style={{ marginTop: '-14px' }}>
          <img src={LogoTop} />
        </IconTopWrapper>
        <SocialIconsWrapper>
          {SocialIcons.map((si) => (
            <img src={si} />
          ))}
        </SocialIconsWrapper>
      </LogoWrapperMobile>
      <LogoWrapperDesktop>
        <IconTopWrapper>
          <img src={LogoTopWhite} style={{ width: '40px' }} />
        </IconTopWrapper>
        <IconTopWrapper style={{ marginTop: '-14px', height: '20px' }}>
          <img src={LogoSynthiWhite} />
        </IconTopWrapper>
        <SocialIconsWrapper>
          {SocialIconsWhite.map((si) => (
            <img src={si} />
          ))}
        </SocialIconsWrapper>
      </LogoWrapperDesktop>
      <BottomInfo>
        <CopyRight>
          Copyright by DREAMAI TECHNOLOGIES {new Date().getFullYear()}
        </CopyRight>
        {/* <LinkWrapper >BLOG</LinkWrapper> */}
        <LinkWrapper
          style={{ marginTop: '32px' }}
          onClick={() => window.open('/Polityka_Prywatności.pdf')}
        >
          Polityka prywatności
        </LinkWrapper>
        <LinkWrapper onClick={() => window.open('/Regulamin.pdf')}>
          Regulamin
        </LinkWrapper>
        <LinkWrapper to="mailto:bok@synthiaiapp.com">
          bok@synthiaiapp.com
        </LinkWrapper>
      </BottomInfo>
    </Wrapper>
  );
};

export default Footer;

import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import React, { useContext, useEffect, useState } from "react";
import { ButtonWrapper, CustomButton } from "@/pages/demo/styles/wrappers";
import { useMutation } from "react-query";
import { updateLeadHistory } from "@/api/query/lead-history";
import { CONVERSATION_VIEWS_ENUM } from "../../demoScript.enums";
import { useTypeWritterFlow } from "@/common/hooks/useTypeWritterFlow";
import BotMessage from "../Message/BotMessage";
import { Howl } from "howler";
import { HOBBIES } from "./hobbyMessage.constants";
import { getGoodAnswer } from "./hobbyMessage.helper";
import Animation1 from "@/common/images/animation-1.gif";

const HobbysMessage = () => {
  const {
    setConversationParts,
    data,
    setData,
    Recording6,
    Recording5A,
    Recording5B,
    setPageNumber,
    Recording5C,
  } = useContext(DemoConversationContext);

  const [selectedHobbies, setSlectedHobbies] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const {
    start,
    results: [text1, text2],
  } = useTypeWritterFlow({
    flowItems: [
      {
        text: getGoodAnswer(data?.name, data?.level).text,
        typing: 45,
        audio: getGoodAnswer(
          data?.name,
          data?.level,
          Recording5A,
          Recording5B,
          Recording5C
        ).audio,
      },
      {
        text: `To make our lessons more interesting and engaging for you, I have a list of 15 topics. Please choose 3 that you find most interesting: `,
        audio: new Howl({
          src: [Recording6],
        }),
      },
    ],
    onEnd: () => {
      setShowButtons(true);
    },
  });

  useEffect(() => {
    start()();
  }, []);

  useEffect(() => {
    if (text2?.length === 1) {
      const element = document.getElementById("section-4");
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [text2]);

  const updateLeadMutation = useMutation({
    mutationFn: (value) => updateLeadHistory(value._id, value),
  });

  const handleSelectButton = (userChoise) => {
    if (selectedHobbies.length >= 3) {
      return;
    }
    setSlectedHobbies((prev) => [...prev, userChoise]);
  };

  useEffect(() => {
    if (selectedHobbies.length === 3) {
      window.gtag("event", "click_question_5");
      const payload = data;
      payload.hobbies = selectedHobbies;
      payload._id = localStorage.getItem("lead-history-id");

      updateLeadMutation.mutate(payload);

      setData((prev) => ({ ...prev, hobbies: selectedHobbies }));

      setConversationParts(CONVERSATION_VIEWS_ENUM.TALKING);
    }
  }, [selectedHobbies]);

  return (
    <>
      <BotMessage
        text={text1}
        translation={getGoodAnswer(data?.name, data?.level).translation}
        showTranslateIcon={showButtons}
        id="section-4"
      />
      <BotMessage
        text={text2}
        translation={` Aby uczynić nasze lekcje bardziej interesującymi i angażującymi dla
        Ciebie, mam listę 15 tematów. Proszę wybierz trzy, które uważasz za
        najbardziej interesujące: `}
        showTranslateIcon={showButtons}
      />
      {showButtons && (
        <ButtonWrapper style={{ flexWrap: "wrap" }}>
          {HOBBIES.map(({ name, icon, mobile_name }) => (
            <CustomButton
              className="fade__in__animation"
              onClick={() => handleSelectButton(name)}
              key={name}
              style={{
                maxWidth: "350px",
                padding: "10px",
                textAlign: "left",
                textTransform: "unset",
                alignItems: "center",
                background: `${
                  selectedHobbies.includes(name) ? "#730BDB" : "#ECE5F2"
                }`,
                color: `${selectedHobbies.includes(name) ? "white" : "black"}`,
              }}
            >
              <img src={icon} alt={`Icon ${name}`} width={15} />{" "}
              {window.innerWidth > 600 ? name : mobile_name}
            </CustomButton>
          ))}
        </ButtonWrapper>
      )}
      {showLoading && (
        <div
          style={{
            textAlign: "center",
            marginTop: "-60px",
            marginLeft: "-20px",
          }}
        >
          <img src={Animation1} style={{ width: "120px" }} />
        </div>
      )}
    </>
  );
};

export default HobbysMessage;

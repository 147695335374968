import { styled } from "styled-components";

export const Wrapper = styled.section`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  gap: 40px;
`;
export const IconWrapper = styled.div`
  width: 80px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  div {
    display: flex;
    align-items: flex-start;
  }

  p {
    margin: 0;
    color: #29185e;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 230px;
  }
`;

export const MoneyInfo = styled.p`
  color: #29185e;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 40px 0 0 0;
`;

export const MoneyBottomInfo = styled.p`
  color: #29185e;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 25px 0;
`;

export const Title = styled.h2`
  color: #29185e;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 0;

  @media (max-width: 600px) {
    margin-top: -50px;
    font-size: 28px;
  }
`;

export const PlanWrapper = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    max-width: 300px;
    margin-top: -20px;
    gap: 10px;
  }
`;

export const MarginPlan = styled.div`
  padding: 30px 50px;
  border-radius: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media (max-width: 600px) {
    background: none;
    box-shadow: none;
  }
`;

export const MainPlan = styled.div`
  display: flex;
  padding: 30px 35px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  border-radius: 16px;
  background: linear-gradient(
    46deg,
    rgba(6, 191, 214, 0.6) 0%,
    rgba(2, 214, 156, 0.6) 100%
  );

  @media (max-width: 600px) {
    padding: 10px 30px;
    gap: 10px;
  }
`;

export const TopInfo = styled.h3`
  margin: 0;
  color: #29185e;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const Price = styled.h2`
  color: #29185e;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 40px 0 0 0;

  @media (max-width: 600px) {
    margin-top: 20px;
    font-size: 30px;
  }
`;

export const ButtonBuy = styled.button`
  display: inline-flex;
  padding: 12px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  border: none;

  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.05);
  }
`;

export const SubTop = styled.div`
  color: #29185e;
  margin-top: 4px;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 20px;
  margin-bottom: 80px;
`;
const Border = styled.div`
  height: 2px;
  max-width: 1400px;
  background: #d2e6ea;
  margin-bottom: 20px;
`;
const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  color: #0b2f45;

  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Fotter = ({ data = {} }) => {
  return (
    <Wrapper>
      <Border />
      <Line>
        <div>Copyright©{new Date().getFullYear()}</div>
        <div> DreamAi Technologies </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => window.open("/Polityka_Prywatności.pdf")}
        >
          {" "}
          | {data.privacy}
        </div>
        <div
          onClick={() => window.open("/Regulamin.pdf")}
          style={{ cursor: "pointer" }}
        >
          {" "}
          | {data.rules}
        </div>
        <div> | bok@synthiaiapp.com</div>
      </Line>
    </Wrapper>
  );
};

export default Fotter;

import React, { useContext } from 'react';
import styled from 'styled-components';
import PaymentLock from '../images/plan/payment-lock.svg';
import Payu from '../images/plan/payu-green.png';
import Visa from '../images/plan/visa-icon-png.png';
import mastercard from '../images/plan/mastercard-icon-png.png';
import applepay from '../images/plan/aplle-pay-icon-png.png';
import gplay from '../images/plan/google-pay-mark-8001.svg';
import blik from '../images/plan/blik-icon-png.png';
import { getNavigationPathApp } from '@/common/navigation/getNavigationPathApp';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';

const PlanWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
`;

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  max-width: 300px;

  @media (min-width: 600px) {
    max-width: 800px;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const PlanBox = styled.div`
  width: 270px;
  height: 220px;
  border-radius: 12px;
  background: #28064a;
  padding: 16px;

  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Left = styled.h3`
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: linear-gradient(90deg, #e94f96 0%, #b200f5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;
const Right = styled.div`
  border-radius: 25px;
  border: 1px solid #c553e6;
  display: inline-flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #fff;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Text = styled.div`
  margin-top: 22px;
  color: #fff;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const PlanText = styled.div`
  color: #e94f96;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
const MonthlyPrice = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 12px 0;
`;
const StartButton = styled.button`
  color: #fff;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(0.95);
  }

  width: 200px;
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );
  outline: none;
  border: none;
  margin: 0 auto;
`;

const PaymentsSave = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 5px;
  align-items: center;
`;
const LeftIcon = styled.div``;
const TextPayment = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
`;
const RightIcon = styled.div``;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 26px;
  margin: 22px 0 44px 0;
`;

const OrientationWrapper = styled.div`
  @media (min-width: 600px) {
    margin-top: 34px;
    display: flex;
    gap: 46px;
  }
`;

const ICONS = [
  {
    icon: Visa,
    width: '50px',
  },
  {
    icon: mastercard,
    width: '30px',
  },
  {
    icon: applepay,
    width: '40px',
  },
  {
    icon: gplay,
    width: '50px',
  },
  {
    icon: blik,
    width: '40px',
  },
];

const Plan = () => {
  const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);

  return (
    <PlanWrapper id="plan">
      <Title>Wybierz plan odpowiedni dla siebie</Title>
      <OrientationWrapper>
        <PlanBox>
          <TopWrapper>
            <Left>PREMIUM</Left>
            <Right>Najlepsza oferta</Right>
          </TopWrapper>
          <Text>nieograniczony dostęp na:</Text>
          <PlanText>6 MIESIĘCY</PlanText>
          <MonthlyPrice>29.99 zł/mies</MonthlyPrice>
          <StartButton
            onClick={() => {
              handleFbStats('AddToCart', {
                price: 179.94,
                currency: 'PLN',
              });
              handleSendIPstats(
                IP_STATS_ENUM.REGISTER_BY_PREMIUM,
                null,
                'LP_PRODUCT_1',
              );
              window?.fbq('track', 'AddToCart', {
                price: 179.94,
                currency: 'PLN',
              });
              window?.gtag('event', 'add_to_cart', {
                price: 179.94,
                currency: 'PLN',
              });
              window.location.assign(getNavigationPathApp('register'));
            }}
          >
            ZACZNIJ NAUKĘ (oszczędzasz 50%)
          </StartButton>
        </PlanBox>
        <PlanBox
          style={{
            background: '#F4F6F8',
          }}
        >
          <TopWrapper>
            <Left>PRO</Left>
            <Right
              style={{
                color: 'black',
              }}
            >
              Najczęściej wybierane
            </Right>
          </TopWrapper>
          <Text
            style={{
              color: 'black',
            }}
          >
            nieograniczony dostęp na:
          </Text>
          <PlanText>3 MIESIĄCE</PlanText>
          <MonthlyPrice
            style={{
              color: 'black',
            }}
          >
            39.99 zł/mies
          </MonthlyPrice>
          <StartButton
            onClick={() => {
              handleSendIPstats(
                IP_STATS_ENUM.REGISTER_BY_PRO,
                null,
                'LP_PRODUCT_1',
              );
              handleFbStats('AddToCart', {
                price: 119.97,
                currency: 'PLN',
              });
              window?.fbq('track', 'AddToCart');
              window?.gtag('event', 'add_to_cart', {
                price: 119.97,
                currency: 'PLN',
              });
              window.location.assign(getNavigationPathApp('register'));
            }}
          >
            ZACZNIJ NAUKĘ (oszczędzasz 33%)
          </StartButton>
        </PlanBox>
        <PlanBox
          style={{
            background: '#F4F6F8',
          }}
        >
          <TopWrapper>
            <Left>BASIC</Left>
          </TopWrapper>
          <Text
            style={{
              color: 'black',
            }}
          >
            nieograniczony dostęp na:
          </Text>
          <PlanText>1 MIESIĄC</PlanText>
          <MonthlyPrice
            style={{
              color: 'black',
            }}
          >
            59.99 zł/mies
          </MonthlyPrice>
          <StartButton
            style={{
              marginTop: '20px',
            }}
            onClick={() => {
              handleSendIPstats(
                IP_STATS_ENUM.REGISTER_BY_BASIC,
                null,
                'LP_PRODUCT_1',
              );
              handleFbStats('AddToCart', {
                price: 59.99,
                currency: 'PLN',
              });
              window?.fbq('track', 'AddToCart', {
                price: 59.99,
                currency: 'PLN',
              });
              window?.gtag('event', 'add_to_cart', {
                price: 59.99,
                currency: 'PLN',
              });
              window.location.assign(getNavigationPathApp('register'));
            }}
          >
            ZACZNIJ NAUKĘ
          </StartButton>
        </PlanBox>
      </OrientationWrapper>
      <PaymentsSave>
        <LeftIcon>
          <img src={PaymentLock} />
        </LeftIcon>
        <TextPayment>Bezpieczne płatności zapewnia</TextPayment>
        <RightIcon>
          <img src={Payu} />
        </RightIcon>
      </PaymentsSave>
      <IconsWrapper>
        {ICONS.map((i) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              width: i.width,
            }}
          >
            <img src={i.icon} style={{ width: '100%', height: 'auto' }} />
          </div>
        ))}
      </IconsWrapper>
    </PlanWrapper>
  );
};

export default Plan;

import { updateLeadHisotryClickReady } from "@/api/query/lead-history";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { ButtonWrapper, CustomButton } from "@/pages/demo/styles/wrappers";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTypeWritterFlow } from "@/common/hooks/useTypeWritterFlow";
import { CONVERSATION_VIEWS_ENUM } from "../../demoScript.enums";
import BotMessage from "../Message/BotMessage";
import { Howl } from "howler";

const WelcomeMessage = () => {
  const { setConversationParts, RecordingSection1, RecordingSection1a } =
    useContext(DemoConversationContext);
  const [picked, setPicked] = useState();
  const [showButtons, setShowButtons] = useState(false);
  const {
    start,
    results: [text1, text2],
  } = useTypeWritterFlow({
    flowItems: [
      {
        text: `Before we get started with our practice, I'd like to ask you a few basic questions to get to know you better. This will help me tailor our sessions specifically to your needs and interests. Don't worry, there are no right or wrong answers - it's just to get the conversation going.  `,
        typing: 42,
        audio: new Howl({
          src: [RecordingSection1],
        }),
        timeout: 2000,
      },
      {
        text: `Are you ready? `,
        audio: new Howl({
          src: [RecordingSection1a],
        }),
      },
    ],
    onEnd: () => {
      setTimeout(() => {
        setShowButtons(true);
      }, 2500);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      start()();
    }, 0);
  }, []);

  const createLeadMutation = useMutation({
    mutationFn: (value) => updateLeadHisotryClickReady(value),
  });

  const handleClick = (userChoice) => {
    if (picked) {
      return;
    }
    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");

    createLeadMutation.mutate(payload);
    setConversationParts(CONVERSATION_VIEWS_ENUM.NAME);
    setPicked(userChoice);
  };

  return (
    <>
      <BotMessage text={text1} />
      <BotMessage text={text2} />
      {showButtons && (
        <ButtonWrapper>
          <CustomButton
            className="fade__in__animation"
            onClick={() => {
              window.gtag("event", "click_question_1");
              handleClick(1);
            }}
            style={{
              background: `${picked === 1 ? "#730BDB" : "#ECE5F2"}`,
              color: `${picked === 1 ? "white" : "black"}`,
            }}
          >
            Let's begin!
          </CustomButton>
          <CustomButton
            className="fade__in__animation"
            onClick={() => {
              window.gtag("event", "click_question_1");
              handleClick(2);
            }}
            style={{
              background: `${picked === 2 ? "#730BDB" : "#ECE5F2"}`,
              color: `${picked === 2 ? "white" : "black"}`,
            }}
          >
            Can't wait!
          </CustomButton>
        </ButtonWrapper>
      )}
    </>
  );
};

export default WelcomeMessage;

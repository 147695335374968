import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import chat from '../video/Czat.mp4';
import hints from '../video/podpowiedzi_1.mp4';
import voice from '../video/Voice.mp4';
import topic from '../video/topic.mp4';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h2`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  max-width: 290px;
  margin: 0;

  @media (min-width: 600px) {
    max-width: 800px;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const Description = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 320px;

  @media (min-width: 600px) {
    max-width: 800px;
    color: #29185e;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Box = styled.div`
  width: 300px;
  padding: 24px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #ece5f2;
  margin-top: 22px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 2;

  @media (min-width: 600px) {
    width: 800px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 100px;

    div {
      width: 300px;
    }
  }
`;

const BoxTitle = styled.h3`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
`;

const BoxDescription = styled.p`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0 4px 0;
`;

const BoxWhite = styled.div`
  width: 326px;
  height: 256px;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;

  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
  }
`;

const CONFIG = [
  {
    title: 'Interaktywne rozmowy z Tutorami AI',
    text: 'Zanurz się w angażujących, interaktywnych rozmowach z Tutorami AI, które ułatwiają naukę i dodadzą pewności siebie w mówieniu po angielsku.',
    image: '',
    video: chat,
  },
  {
    title: 'Ćwiczenia mówienia i formułowania zdań',
    text: 'Z Synthi AI, każde zdanie przybliża Cię do biegłości językowej, dzięki praktycznym ćwiczeniom na formułowanie myśli i wypowiedzi.',
    image: '',
    video: hints,
  },
  {
    title: 'Natychmiastowe tłumaczenia i podpowiedzi',
    text: 'Przekraczaj bariery językowe z Synthi AI, korzystając z natychmiastowych tłumaczeń i podpowiedzi, które usprawniają naukę.',
    image: '',
    video: voice,
  },
  {
    title: 'Symulacje sytuacji życiowych z AI',
    text: 'Zanurz się w symulowanych, życiowych scenariuszach z AI, by swobodnie komunikować się w każdej sytuacji.',
    image: '',
    video: topic,
  },
];

const OneElement = ({ element }) => {
  const videoRef = useRef(null);
  const [observer, setObserver] = useState(null);

  const handleViewChange = useCallback((entries) => {
    for (let entry of entries) {
      if (entry.intersectionRatio > 0.5) {
        if (videoRef.current) {
          videoRef.current.play();
        }
      } else {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleViewChange, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });
    setObserver(observer);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [handleViewChange]);

  return (
    <Box>
      <div>
        <BoxTitle>{element.title}</BoxTitle>
        <BoxDescription>{element.text}</BoxDescription>
      </div>
      <BoxWhite>
        {element.video && (
          <video ref={videoRef} autoPlay={true} muted={true} playsInline={true}>
            <source src={element.video} type="video/mp4" />
          </video>
        )}
      </BoxWhite>
    </Box>
  );
};

const WhatYouWillGet = () => {
  return (
    <Wrapper>
      <Title>Odkryj korzyści nauki z Synthi AI</Title>
      <Description>
        Poznaj funkcje, które sprawiają, że Synthi AI jest wyjątkowym narzędziem
        do nauki angielskiego, zapewniającym szybkie i skuteczne wyniki!
      </Description>
      {CONFIG.map((c) => (
        <OneElement element={c} key={c.title} />
      ))}
    </Wrapper>
  );
};

export default WhatYouWillGet;

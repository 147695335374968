import { styled } from "styled-components";

export const InputWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${(props) => (props.redBorder ? "red" : "#ECE5F2")};
  background: #ece5f2;
  max-width: fit-content;
  align-self: flex-end;
  margin: 20px 0;

  @media (max-width: 600px) {
    margin: 10px 0;
  }
`;
export const NameInput = styled.input`
  border: none;
  background-color: transparent;
  margin: 20px 5px 20px 10px;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.24px;

  @media (max-width: 600px) {
    margin: 10px 5px 10px 5px;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: black;
  }

  &:-ms-input-placeholder {
    color: black;
  }

  &::-ms-input-placeholder {
    color: black;
  }
`;
export const IconSendWrapper = styled.div`
  background-color: #ece5f2;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 15px 15px 0;
`;

export const IconSend = styled.img`
  margin-left: 0px;
  width: 17.608px;
  height: 17.602px;
  transform: rotate(4.943deg);
  cursor: pointer;
`;

import React, { useContext } from 'react';
import { styled } from 'styled-components';
import MockUp from '@/common/images/mockuptelefon_destop.png';
import Hand from '@/common/images/index-finger-pressing-something-2.png';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { getNavigationPath } from '@/common/navigation/getNavigationPath';

const Wrapper = styled.div`
  margin-top: 40px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: 600px) {
    display: none;
  }
`;

const Title = styled.h2`
  color: #29185e;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 0;

  @media (min-width: 600px) {
    max-width: 600px;
  }
`;

const Subtitle = styled.p`
  color: #29185e;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  max-width: 360px;
  white-space: pre;

  @media (min-width: 600px) {
    max-width: 600px;
    min-width: 600px;
  }
`;

const CheckButton = styled.button`
  margin-top: 20px;
  display: flex;
  width: 320px;
  padding: 13px 22px;
  justify-content: center;
  align-items: center;
  gap: 27px;
  border-radius: 50px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);

  border: none;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    margin-top: 80px;
  }
`;

const WrapperDesktop = styled.div`
  display: none;

  @media (min-width: 600px) {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const ImageWrapperDesktop = styled.div`
  max-width: 400px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Subtitle2 = styled.div`
  margin: 40px 0 60px 0;
  color: #29185e;
  max-width: 400px;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Mockup = ({ data }) => {
  const navigate = useNavigate();
  const { handleSendIPstats } = useContext(IpStatsContext);

  const clickStartDemoOnBuyPageMutation = useMutation({
    mutationFn: (value) => clickStartDemoOnBuyPageMutation(value),
  });

  const handleClick = () => {
    handleSendIPstats(IP_STATS_ENUM.CLICK_DEMO);

    const payload = {};
    payload._id = localStorage.getItem('lead-history-id');

    clickStartDemoOnBuyPageMutation.mutate(payload);

    window.gtag('event', 'start_trial_bottom');

    navigate(getNavigationPath('/demo'));
  };

  return (
    <>
      <Wrapper>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
        <div>
          <img src={MockUp} />
        </div>
        <div style={{ position: 'absolute', marginTop: '330px', right: '0px' }}>
          <img src={Hand} />
        </div>
        <CheckButton id="start_trial" onClick={() => handleClick()}>
          {data.buttonText}
        </CheckButton>
      </Wrapper>
      <WrapperDesktop>
        <ImageWrapperDesktop>
          <img src={MockUp} style={{ width: '100%', height: 'auto' }} />
        </ImageWrapperDesktop>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '-80px',
          }}
        >
          <Title>{data.subtitle}</Title>
          <Subtitle2>{data.subtitleSub}</Subtitle2>
          <CheckButton id="start_trial" onClick={() => handleClick()}>
            {data.buttonText}
          </CheckButton>
        </div>
      </WrapperDesktop>
    </>
  );
};

export default Mockup;

import React, { useState } from "react";
import { TalkMessageWrapper } from "@/pages/demo/styles/wrappers";
import { ContentTranslationWrapper } from "../WelcomeMessage/welcomeMessage.styles";

const BotMessage = ({ text, id }) => {
  if (!text) return null;

  return (
    <>
      <ContentTranslationWrapper id={id}>
        <TalkMessageWrapper
          style={{
            borderRadius: "16px 16px 16px 0px",
          }}
        >
          {text}
        </TalkMessageWrapper>
      </ContentTranslationWrapper>
    </>
  );
};

export default BotMessage;

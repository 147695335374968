import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const WrapperEffectivity = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  margin-top: 32px;

  position: relative;
`;

const Title = styled.h1`
  margin: 0;
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  max-width: 300px;

  @media (min-width: 600px) {
    max-width: 800px;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const Color = styled.span`
  color: #730bdb;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media (min-width: 600px) {
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const Description = styled.p`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 295px;

  @media (min-width: 600px) {
    color: #29185e;
    max-width: 800px;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  width: 300px;
  padding: 30px 24px 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  border-radius: 12px;
  background: #ece5f2;
  z-index: 2;
`;

const TitleBox = styled.p`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
`;

const ColorBox = styled.span`
  color: #730bdb;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0px 5px;
`;

const WhiteBox = styled.div`
  width: 300px;
  height: 240px;
  border-radius: 8px;
  background: #fff;
  position: relative;
`;

const TitleTop = styled.div`
  color: #c0bac1;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 12px;
  top: 8px;
`;

const TitleBottom = styled.div`
  position: absolute;
  transform: rotate(-90deg);
  color: #c0bac1;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  left: -4px;
  bottom: 32px;
`;

const ElementsWrapper = styled.div`
  display: flex;
  gap: 12px;
  position: absolute;
  left: 50px;
  bottom: 10px;

  .Element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .Element.active {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;

const Top = styled.div`
  color: #c0bac1;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Middle = styled.div`
  border-radius: 10px 10px 0px 0px;
  width: 16px;
  height: ${(prev) => prev.height}px;
  background-color: ${(prev) => prev.color};
`;

const Bottom = styled.div`
  color: #c0bac1;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const RoseBox = styled.div`
  border-radius: 8px;
  background: #fcf9ff;
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 310px;
  padding-left: 5px;
`;

const BigText = styled.h2`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
`;

const Border = styled.div`
  width: 1px;
  height: 35px;
  background: #c0bac1;
  margin: auto 5px;
`;

const SmallText = styled.p`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: auto 0;
`;

const OrientationWrapper = styled.div`
  z-index: 1;

  @media (min-width: 600px) {
    display: flex;
    gap: 60px;
  }
`;

const config = [
  {
    number: 508,
    color: 'rgba(101, 6, 117, 0.30)',
  },
  {
    number: 2227,
    color: 'rgba(101, 6, 117, 0.30)',
  },
  {
    number: 5067,
    color: '#730BDB',
    bold: true,
  },
  {
    number: 5989,
    color: 'rgba(101, 6, 117, 0.40)',
  },
  {
    number: 6721,
    color: 'rgba(101, 6, 117, 0.70)',
  },
  {
    number: 8199,
    color: '#650675',
  },
];

const Effectivity = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [percentage, setPercentage] = useState(16);
  const [percentageBarier, setPercentageBarier] = useState(85);
  const [people, setPeople] = useState(1500);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prevPercentage) => {
        if (prevPercentage < 20) {
          return prevPercentage + 1;
        } else {
          clearInterval(interval);
          return prevPercentage;
        }
      });
    }, 400);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPeople((prevPercentage) => {
        if (prevPercentage < 1576) {
          return prevPercentage + 1;
        } else {
          clearInterval(interval);
          return prevPercentage;
        }
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentageBarier((prevPercentage) => {
        if (prevPercentage < 95) {
          return prevPercentage + 1;
        } else {
          clearInterval(interval);
          return prevPercentage;
        }
      });
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <WrapperEffectivity>
      <Title>
        <Color>10x szybsza</Color> niż tradycyjne metody
      </Title>
      <Description>
        Nauka przez rozmowy z Synthi AI – szybsza i skuteczniejsza niż inne
        metody. Przekształć swoją znajomość języka w biegłość dzięki dynamicznym
        dialogom.
      </Description>
      <OrientationWrapper>
        <BoxWrapper>
          <TitleBox>
            W<ColorBox>trzy tygodnie</ColorBox>
            użytkownicy Synthi AI zyskują pewność siebie i wypowiadają na głos
            ponad 5 000 słów.
          </TitleBox>
          <WhiteBox>
            <TitleTop>ILOŚĆ SŁÓW</TitleTop>
            <TitleBottom>TYDZIEN</TitleBottom>
            <ElementsWrapper>
              {config.map((c, i) => (
                <Element
                  className={i <= activeIndex ? 'Element active' : 'Element'}
                >
                  <Top
                    style={{
                      fontWeight: c.bold ? '700' : 'normal',
                      color: c.bold ? c.color : '',
                    }}
                  >
                    {c.number}
                  </Top>
                  <Middle height={c.number / 50} color={c.color}></Middle>
                  <Bottom
                    style={{
                      fontWeight: c.bold ? '700' : 'normal',
                      color: c.bold ? c.color : '',
                    }}
                  >
                    {i + 1}
                  </Bottom>
                </Element>
              ))}
            </ElementsWrapper>
          </WhiteBox>
        </BoxWrapper>
        <BoxWrapper
          style={{
            marginTop: '12px',
            gap: '20px',
          }}
        >
          <TitleBox>Sukces mierzony wynikami:</TitleBox>
          <RoseBox>
            <BigText>{percentageBarier}%</BigText>
            <Border />
            <SmallText>
              użytkowników twierdzi, że przełamało barierę mówienia po angielsku
            </SmallText>
          </RoseBox>
          <RoseBox style={{ paddingRight: '5px' }}>
            <SmallText>
              słów tygodniowo wypowiadają użytkownicy Synthi AI
            </SmallText>
            <Border />
            <BigText>{people}</BigText>
          </RoseBox>
          <RoseBox>
            <BigText>{percentage}%</BigText>
            <Border />
            <SmallText>
              o tyle zwiększa się zasób słownictwa po 3 tygodniach korzystania z
              Synthi AI
            </SmallText>
          </RoseBox>
        </BoxWrapper>
      </OrientationWrapper>
    </WrapperEffectivity>
  );
};

export default Effectivity;

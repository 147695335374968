import { updateLeadHistory } from "@/api/query/lead-history";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { ButtonWrapper, TextInfomation } from "@/pages/demo/styles/wrappers";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { CONVERSATION_VIEWS_ENUM } from "../../demoScript.enums";
import { useTypeWritterFlow } from "@/common/hooks/useTypeWritterFlow";
import BotMessage from "../Message/BotMessage";
import { Howl } from "howler";
import { ButtonWrapperAge, CustomButtonAge } from "./ageMessage.styles";
import { YERS_OPTIONS } from "./ageMessage.constsnts";

const AgeMessage = () => {
  const { data, setConversationParts, setData, Recording3 } = useContext(
    DemoConversationContext
  );
  const [showAction, setShowAction] = useState();

  const {
    start,
    results: [text],
  } = useTypeWritterFlow({
    flowItems: [
      {
        text: `Nice to meet you, ${data?.name}! How old are you? Knowing your age will help me understand your learning needs and adapt our English lessons accordingly. `,
        typing: 42,
        audio: new Howl({
          src: [Recording3],
        }),
      },
    ],
    onEnd: () => {
      const element = document.getElementById("section-2");
      element.scrollIntoView({ behavior: "smooth" });
      setShowAction(true);
    },
  });

  useEffect(() => {
    start()();
  }, []);

  const updateLeadMutation = useMutation({
    mutationFn: (value) => updateLeadHistory(value._id, value),
  });

  const handleSelectAge = (userChoice) => {
    if (data?.age) {
      return;
    }

    const payload = data;
    payload.age = userChoice;
    payload._id = localStorage.getItem("lead-history-id");

    updateLeadMutation.mutate(payload);
    window.gtag("event", "click_question_3");

    setConversationParts(CONVERSATION_VIEWS_ENUM.LEVEL);
    setData((prev) => ({ ...prev, age: userChoice }));
  };

  return (
    <>
      <BotMessage text={text} id="section-2" />
      {showAction && (
        <>
          <ButtonWrapper className="fade__in__animation">
            <TextInfomation>
              I am between {data?.age || "..."} years old.
            </TextInfomation>
          </ButtonWrapper>
          <ButtonWrapperAge className="fade__in__animation">
            {YERS_OPTIONS.map(({ name }) => (
              <CustomButtonAge
                onClick={() => handleSelectAge(name)}
                key={name}
                style={{
                  background: `${data?.age === name ? "#730BDB" : "#ECE5F2"}`,
                  color: `${data?.age === name ? "white" : "black"}`,
                }}
              >
                {name}
              </CustomButtonAge>
            ))}
          </ButtonWrapperAge>
        </>
      )}
    </>
  );
};

export default AgeMessage;

import React, { useContext } from 'react';
import {
  ButtonBuy,
  MarginPlan,
  SubTop,
  Title,
  TopInfo,
  Wrapper,
} from './planSection.styles';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { getNavigationPathApp } from '@/common/navigation/getNavigationPathApp';

export const PlanWrapper = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    max-width: 300px;
    margin-top: -20px;
    gap: 10px;
  }
`;

export const MainPlan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 280px;
  height: 320px;
  border-radius: 16px;
  background: #29185e;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    transform: scale(0.95);
    cursor: pointer;
  }
`;

export const Price = styled.h2`
  color: #29185e;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0;
`;

const LeftRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 280px;
  height: 320px;
  border-radius: 16px;
  background: #f4f6f8;

  transition: all 0.3s;

  &:hover {
    transform: scale(0.95);
    cursor: pointer;
  }
`;

const WrapperIcon = styled.div`
  top: 48%;
  position: absolute;
  z-index: 99999;
  border: none;
`;

const PlansWrapper = styled.div`
  display: flex;
  gap: 25px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const PlanName = styled.div`
  color: #29185e;

  text-align: center;
  font-family: Work Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TextAdionalInfo = styled.div`
  color: #29185e;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Flex1 = styled.div`
  flex: 1;
`;

const Border = styled.div`
  width: 230px;
  height: 1px;
  background: rgba(79, 167, 180, 0.2);
`;

const SaveWrapepr = styled.div`
  display: inline-flex;
  padding: 3px 0;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #e94f96 0%, #b200f5 100%);
  width: 280px;
  margin-top: 10px;

  @media (max-width: 1000px) {
    width: 300px;
  }

  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Discount = styled.div`
  color: #29185e;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: 1px line-through red;
`;

const WrapperElement = styled.div`
  width: 360px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const MobileWrapper = styled.div`
  display: flex;

  @media (min-width: 1200px) {
    display: none;
  }
`;

const PlanSection = ({ data }) => {
  const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);
  return (
    <>
      <Wrapper id="plan-offer">
        <Title>{data.title} </Title>
        <MarginPlan>
          <TopInfo style={{ marginTop: '10px' }}>{data.subTitle}</TopInfo>
          <PlansWrapper>
            <LeftRightWrapper
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PREMIUM);
                handleFbStats('AddToCart', {
                  price: 59.99,
                  currency: 'PLN',
                });
                window?.fbq('track', 'AddToCart');
                window?.gtag('event', 'add_to_cart');
                localStorage.setItem('picked_plan', '1');
                window.location.assign(getNavigationPathApp('register'));
              }}
            >
              <PlanName>Basic</PlanName>
              <Border />
              <Flex1 />
              <TextAdionalInfo>{data.basicText}</TextAdionalInfo>
              <Flex1 />
              <Price>{data.basicPriceText}</Price>
              <SubTop style={{ color: 'white' }}></SubTop>
            </LeftRightWrapper>
            <MainPlan
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PRO);
                window?.fbq('track', 'AddToCart');
                window.gtag('event', 'add_to_cart');
                window.gtag('event', 'conversion', {
                  send_to: 'AW-11121636531/sbTqCNnOmZQZELPpmrcp',
                });
                handleFbStats('AddToCart', {
                  price: 119.97,
                  currency: 'PLN',
                });
                localStorage.setItem('picked_plan', '2');
                window.location.assign(getNavigationPathApp('register'));
              }}
            >
              <PlanName style={{ color: 'white' }}>Pro</PlanName>
              <Border style={{ background: 'white' }} />
              <SubTop style={{ color: 'white' }}>{data.proTopText}</SubTop>
              <Flex1 />
              <TextAdionalInfo style={{ color: 'white' }}>
                {data.proMidText}
              </TextAdionalInfo>
              <Flex1 />
              <Discount style={{ color: 'white' }}>
                {data.basicPriceText}
              </Discount>
              <Price style={{ color: 'white' }}>{data.proPriceText}</Price>
              <SubTop style={{ fontStyle: 'normal', color: 'white' }}>
                {data.proFinalPriceText}
              </SubTop>
            </MainPlan>
            <LeftRightWrapper
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PREMIUM);
                window?.fbq('track', 'AddToCart');
                window.gtag('event', 'add_to_cart');
                window.gtag('event', 'conversion', {
                  send_to: 'AW-11121636531/sbTqCNnOmZQZELPpmrcp',
                });
                handleFbStats('AddToCart', {
                  price: 179.94,
                  currency: 'PLN',
                });
                localStorage.setItem('picked_plan', '3');
                window.location.assign(getNavigationPathApp('register'));
              }}
            >
              <PlanName>Premium</PlanName>
              <Border />
              <SubTop>{data.premiumTopText}</SubTop>
              <SaveWrapepr>{data.premiumSaveText}</SaveWrapepr>
              <Flex1 />
              <TextAdionalInfo>{data.premiumMiddleText}</TextAdionalInfo>
              <Flex1 />
              <Discount>{data.basicPriceText}</Discount>
              <Price>{data.premiumPriceText}</Price>
              <SubTop style={{ fontStyle: 'normal' }}>
                {data.premiumFullPriceText}
              </SubTop>
            </LeftRightWrapper>
          </PlansWrapper>
          <MobileWrapper>
            <Carousel
              axis="horizontal"
              autoPlay={true}
              infiniteLoop={true}
              width="360px"
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <WrapperIcon onClick={onClickHandler} style={{ left: '0' }}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{ fontSize: '30px' }}
                  />
                </WrapperIcon>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <WrapperIcon onClick={onClickHandler} style={{ right: '0' }}>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ fontSize: '30px' }}
                  />
                </WrapperIcon>
              )}
            >
              <WrapperElement
                onClick={() => {
                  handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PREMIUM);
                  handleFbStats('AddToCart', {
                    price: 59.99,
                    currency: 'PLN',
                  });
                  localStorage.setItem('picked_plan', '1');
                  window?.fbq('track', 'AddToCart');
                  window.gtag('event', 'add_to_cart');
                  window.location.assign(getNavigationPathApp('register'));
                }}
              >
                <LeftRightWrapper>
                  <PlanName>Basic</PlanName>
                  <Border />
                  <Flex1 />
                  <TextAdionalInfo>{data.basicText}</TextAdionalInfo>
                  <Flex1 />
                  <Price>{data.basicPriceText}</Price>
                  <SubTop style={{ color: 'white' }}></SubTop>
                </LeftRightWrapper>
              </WrapperElement>
              <WrapperElement
                onClick={() => {
                  handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PRO);
                  window?.fbq('track', 'AddToCart');
                  window.gtag('event', 'add_to_cart');
                  handleFbStats('AddToCart', {
                    price: 119.97,
                    currency: 'PLN',
                  });
                  localStorage.setItem('picked_plan', '2');
                  window.location.assign(getNavigationPathApp('register'));
                }}
              >
                <MainPlan>
                  <PlanName style={{ color: 'white' }}>Pro</PlanName>
                  <Border
                    style={{
                      background:
                        'linear-gradient(90deg, #E94F96 0%, #B200F5 100%)',
                    }}
                  />
                  <SubTop style={{ color: 'white' }}>{data.proTopText}</SubTop>
                  <Flex1 />
                  <TextAdionalInfo style={{ color: 'white' }}>
                    {data.proMidText}
                  </TextAdionalInfo>
                  <Flex1 />
                  <Discount style={{ color: 'white' }}>
                    {data.basicPriceText}
                  </Discount>
                  <Price style={{ color: 'white' }}>{data.proPriceText}</Price>
                  <SubTop style={{ fontStyle: 'normal', color: 'white' }}>
                    {data.proFinalPriceText}
                  </SubTop>
                </MainPlan>
              </WrapperElement>
              <WrapperElement
                onClick={() => {
                  handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_PREMIUM);
                  localStorage.setItem('picked_plan', '3');
                  window?.fbq('track', 'AddToCart');
                  window.gtag('event', 'add_to_cart');
                  handleFbStats('AddToCart', {
                    price: 179.94,
                    currency: 'PLN',
                  });
                  window.location.assign(getNavigationPathApp('register'));
                }}
              >
                <LeftRightWrapper>
                  <PlanName>Premium</PlanName>
                  <Border />
                  <SubTop>{data.premiumTopText}</SubTop>
                  <SaveWrapepr> {data.premiumSaveText}</SaveWrapepr>
                  <Flex1 />
                  <TextAdionalInfo>{data.premiumMiddleText}</TextAdionalInfo>
                  <Flex1 />
                  <Discount> {data.basicPriceText}</Discount>
                  <Price>{data.premiumPriceText}</Price>
                  <SubTop style={{ fontStyle: 'normal' }}>
                    {data.premiumFullPriceText}
                  </SubTop>
                </LeftRightWrapper>
              </WrapperElement>
            </Carousel>
          </MobileWrapper>
          <ButtonBuy
            onClick={() => {
              handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_BUY);
              window?.fbq('track', 'AddToCart');
              window.gtag('event', 'add_to_cart');
              handleFbStats('AddToCart', {
                price: 59.99,
                currency: 'PLN',
              });
              window.location.assign(getNavigationPathApp('register'));
            }}
            style={{ cursor: 'pointer' }}
          >
            {data.buttonText}
          </ButtonBuy>
        </MarginPlan>
      </Wrapper>
    </>
  );
};

export default PlanSection;

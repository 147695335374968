import React, { useContext, useEffect, useLayoutEffect } from 'react';
import Navbar from './components/Navbar';
import styled from 'styled-components';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Effectivity from './components/Effectivity';
import Plan from './components/Plan';
import WhatYouWillGet from './components/WhatYouWillGet';
import Opinions from './components/Opinions';
import NewsLetter from './components/NewsLetter';
import Faq from './components/Faq';
import { EventService } from '@/common/services/eventService';
import { EventNamesEnum } from '@/common/enums/eventNamesEnum';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { useMutation } from 'react-query';
import { createLeadHistory } from '@/api/query/lead-history';
import useQueryMemo from '@/common/hooks/urlMemo';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: #fff;
`;

const HomePage = () => {
  const queryMemo = useQueryMemo();

  const createLeadMutation = useMutation({
    mutationFn: (value) => createLeadHistory(value),
    onSuccess: (data) => {
      localStorage.setItem('lead-history-id', data._id);
    },
  });

  useLayoutEffect(() => {
    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('fbclid') || localStorage.getItem('fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');

    const payload = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
    };

    if (queryMemo.get('utm_campaign')) {
      localStorage.setItem('utm_campaign', queryMemo.get('utm_campaign'));
    }
    if (queryMemo.get('utm_medium')) {
      localStorage.setItem('utm_medium', queryMemo.get('utm_medium'));
    }
    if (queryMemo.get('utm_source')) {
      localStorage.setItem('utm_source', queryMemo.get('utm_source'));
    }
    if (queryMemo.get('utm_content')) {
      localStorage.setItem('utm_content', queryMemo.get('utm_content'));
    }

    if (queryMemo.get('fbclid')) {
      localStorage.setItem('fbclid', queryMemo.get('fbclid'));
    }

    window.gtag('set', { campaignName: utm_campaign });
    window.gtag('set', { campaignSource: utm_medium });
    window.gtag('set', { campaignMedium: utm_source });
    window.gtag('set', { campaignContent: utm_content });
    window.gtag('set', { campaignFbclid: utm_fbclid });

    createLeadMutation.mutate(payload);
    window.gtag('event', 'page_landing');
  }, []);

  const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);

  useEffect(() => {
    EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
      handleSendIPstats(IP_STATS_ENUM.LANDING, null, 'LP_PRODUCT_1'),
    );

    EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
      handleFbStats('PageView', {}),
    );

    return;
  }, []);

  return (
    <Wrapper>
      <Navbar />
      <Hero />
      <Effectivity />
      <Plan />
      <WhatYouWillGet />
      <Opinions />
      {/* <NewsLetter /> */}
      <Faq />
      <Footer />
    </Wrapper>
  );
};

export default HomePage;

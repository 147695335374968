import routes from '@/common/routes/routes';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { Howler } from 'howler';
import { debounce } from './common/functions/debounce';
import { OrientationProblemWrapper } from './App.styles';
import { IpStatsContext } from './common/contexts/IpStatsContext';
import useQueryMemo from './common/hooks/urlMemo';
import userManager from './api/userManager';
import { useMutation } from 'react-query';
import { addFBStats, addIpStats } from './api/query/ipStats';
import { isMobile } from 'react-device-detect';
import { EventService } from './common/services/eventService';
import { EventNamesEnum } from './common/enums/eventNamesEnum';
import MessageQueue, { useMessageQueue } from './common/message';
import { MessageContext } from './common/contexts/MessageContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const [shouldChangeOrientation, setShouldChangeOrientation] = useState();
  const [shouldResize, setShouldResize] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const unloadHowler = () => {
    Howler.unload();
  };

  const triggerResize = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const checkOrientation = () => {
    // if (
    //   detectMobile() &&
    //   window.innerHeight < WindowSizes.TABLET_8_INCH_HEIGHT &&
    //   window.innerHeight < window.innerWidth
    // ) {
    //   setShouldChangeOrientation(() => true);
    // } else if (
    //   !detectMobile() &&
    //   (window.innerWidth < WindowSizes.DESKTOP_MIN_WIDTH ||
    //     window.innerHeight < WindowSizes.DESKTOP_MIN_HEIGHT)
    // ) {
    //   setShouldResize(() => true);
    // } else {
    //   setShouldChangeOrientation(() => false);
    //   setShouldResize(() => false);
    // }
  };

  const queryMemo = useQueryMemo();

  const handleSendIPstatsMutation = useMutation({
    mutationFn: (value) => addIpStats(value),
    onSuccess: () => {},
  });

  const handleFbStatsMutation = useMutation({
    mutationFn: (value) => addFBStats(value),
    onSuccess: () => {},
  });

  const handleSendIPstats = (type, userId = null, pageName = 'LP_1') => {
    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('fbclid') || localStorage.getItem('fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');
    const msclkid = queryMemo.get('msclkid') || localStorage.getItem('msclkid');

    let pageNameToSend = localStorage.getItem('pageName');

    if (!pageNameToSend) {
      localStorage.setItem('pageName', pageName);
      pageNameToSend = pageName;
    }

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
      uid: localStorage.getItem('uid'),
      pageName: pageNameToSend,
      msclkid: msclkid,
    };

    const payload = {};
    payload.user = userId || userManager?.getUser()?.id;
    payload.lead_history = localStorage?.getItem('lead-history-id');
    payload.IpStatsType = type;
    payload.utm = utm;
    payload.device_type = isMobile ? 'mobile' : 'desktop';
    payload.device_description = window.navigator.userAgent;

    handleSendIPstatsMutation.mutate(payload);
  };

  const handleFbStats = (event, eventData) => {
    const utm_fbclid =
      queryMemo.get('fbclid') || localStorage.getItem('fbclid');

    let fbp = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbp='))
      .map((c) => c.split('_fbp=')[1]);
    let fbc = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbc='))
      .map((c) => c.split('_fbc=')[1]);

    fbp = (fbp.length && fbp[0]) || null;
    fbc = (fbc.length && fbc[0]) || null;
    if (!fbc && utm_fbclid) {
      fbc = 'fb.1.' + +new Date() + '.' + utm_fbclid;
    }

    const payload = {
      event: event,
      event_data: eventData,
      fbp: fbp,
      fbclid: fbc,
      user_agent: navigator.userAgent,
      url: window.location.origin + window.location.pathname,
    };

    handleFbStatsMutation.mutate(payload);
  };

  useEffect(() => {
    unloadHowler();
  }, [location.pathname]);

  useEffect(() => {
    const setVh = debounce(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 50);

    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  useEffect(() => {
    const trackingId = localStorage.getItem('uid') || queryMemo.get('uid');

    const saraiUserIdTrack =
      (trackingId != 'undefinded' && trackingId) ||
      new Date().getTime().toString(16) + Math.floor(Math.random() * 100);
    localStorage.setItem('uid', saraiUserIdTrack);

    EventService.publish(EventNamesEnum.TRACING_ID_PROCESSED);

    if (
      ['', 'demo', 'lp'].includes(location.pathname.replaceAll('/', '')) &&
      !queryMemo.get('uid')
    ) {
      setSearchParams({
        uid: saraiUserIdTrack,
      });
    }
  }, [location.pathname]);

  const { addMessage, removeMessage, messages } = useMessageQueue();

  return (
    <>
      {shouldChangeOrientation && (
        <OrientationProblemWrapper>
          Aby wygodnie korzystać z aplikacji, obróć telefon
        </OrientationProblemWrapper>
      )}
      {shouldResize && (
        <OrientationProblemWrapper>
          Aby wygodnie korzystać z aplikacji, rozszerz okno przeglądarki
        </OrientationProblemWrapper>
      )}
      <IpStatsContext.Provider
        value={{
          handleSendIPstats,
          handleFbStats,
        }}
      >
        <MessageQueue
          messages={messages}
          removeMessage={removeMessage}
          addMessage={addMessage}
        />
        <MessageContext.Provider
          value={{
            addMessage,
            removeMessage,
            messages,
          }}
        >
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
                Component={route.component}
              />
            ))}
          </Routes>
        </MessageContext.Provider>
      </IpStatsContext.Provider>
    </>
  );
}

export default App;

import React from 'react';
import Robot from '../../../../images/synthi-icon.svg';
import Arrow from '@/common/images/curved-thin-hand-drawn-arrows-down.svg';
import {
  Card,
  CustomArrow,
  StyledImage,
  TextBottom,
  TextTitle,
  Wrapper,
} from './permisionPopUp.styles';

const PermisionPopUp = () => {
  return (
    <Wrapper>
      <Card className="fade__in__animation">
        <CustomArrow src={Arrow} alt="Synthi AI " />
        <div>
          <TextTitle>Zezwól aplikacji na korzystanie z mikrofonu :)</TextTitle>
          <TextBottom>
            Synthi AI potrzebuje dostępu do mikrofonu, żeby z Tobą porozmawiać.
          </TextBottom>
        </div>
        <StyledImage src={Robot} alt="Synthi AI" />
      </Card>
    </Wrapper>
  );
};

export default PermisionPopUp;

import { styled } from "styled-components";

export const Wrapper = styled.section`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
`;

export const Title = styled.h2`
  color: #29185e;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;

  margin: 0;
`;

export const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ece5f2;
  border-radius: 50%;
  margin: 10px 0;

  img {
    width: 60%;
  }
`;

export const CustomImage = styled.img``;

export const ContentDisplay = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
export const Element = styled.div`
  width: 320px;
  display: inline-flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

export const TitleElement = styled.p`
  color: #650675;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

export const TextElemenet = styled.p`
  width: 250px;
  color: #29185e;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  margin: 0;
`;

export const TextElemenetDown = styled.p`
  color: #29185e;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
`;

export const StartLearningLink = styled.a`
  text-decoration: none;
  display: inline-flex;
  padding: 17px 47px 14px 46px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--iris, #4b4ded);
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

import Leo from '../images/hero/1.png';
import Sara from '../images/hero/3.png';
import Ruby from '../images/hero/2.png';
import Henry from '../images/hero/4.png';

import LeftArr from '../images/hero/arrow-left.svg';
import RightArr from '../images/hero/arrow-right.svg';

import SarahVoice from '../audio/sarah-voice.mp3';
import LeoVoice from '../audio/leo-voice.mp3';
import RubyVoice from '../audio/ruby-voice.mp3';
import HenryVoice from '../audio/henry-voice.mp3';

import Volume from '../images/hero/volume.svg';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 120px;
  }
`;

const MainText = styled.h1`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media (min-width: 600px) {
    color: #29185e;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const Part = styled.span`
  color: #730bdb;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0 10px;

  @media (min-width: 600px) {
    color: #730bdb;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const SubText = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: -20px;

  @media (min-width: 600px) {
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const Description = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 320px;

  margin-top: 22px;

  @media (min-width: 600px) {
    color: #29185e;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 400px;
  }
`;

const PartSmall = styled.span`
  color: #730bdb;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0 5px;
  line-height: normal;

  @media (min-width: 600px) {
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ChoseTutorLink = styled.a`
  display: inline-flex;
  padding: 20px 40px;
  align-items: flex-start;
  gap: 10px;
  text-decoration: none;

  border-radius: 36px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );
  cursor: pointer;

  color: #fff;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  text-transform: uppercase;
  border: none;

  @media (min-width: 600px) {
    display: none;
  }
`;

const ChoseTutorLinkMobile = styled(ChoseTutorLink)`
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const Box = styled.div`
  width: 327px;
  height: 289px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #730bdb;

  margin: 22px 0 68px 0;
`;

const AditionalElement = styled.img`
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 0;
`;

const WrapperIcon = styled.div`
  top: 43%;
  position: absolute;
  z-index: 99999;
  border: none;
  background-color: transparent;
`;

const ElementCaruselWrapper = styled.div`
  width: 460px;
  height: fit-content;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  margin: 10px;

  @media (max-width: 600px) {
    width: 360px;
  }
`;

const ImageWrapper = styled.div`
  width: 350px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 600px) {
    width: 280px;
  }
`;

const NameWrapper = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;
const DescriptionText = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
`;

const DescriptionWrapper = styled.div`
  display: flex;
  width: 338px;
  height: 82px;
  padding: 8px 6px 12px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-radius: 8px;
  background: #fff;

  /* Purple shadow */
  box-shadow: 2px 3px 8.9px 0px rgba(69, 6, 79, 0.3);
`;

const Voice = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;

  width: 56px;
  height: 56px;

  img {
    margin: auto auto;
    width: 32px;
    height: 32px;
  }

  bottom: 100px;
  border-radius: 30px;
  background: #fff;

  box-shadow: 2px 3px 8.9px 0px rgba(69, 6, 79, 0.3);
`;

const WrapperRight = styled.div`
  z-index: 1;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: -40px;
  }
`;

const Desktop = styled.div`
  display: flex;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: flex;

  @media (min-width: 600px) {
    display: none;
  }
`;

const CONFIG = [
  {
    id: 0,
    name: 'Synthi',
    description: 'Practice English in a safe and understanding environment',
    image: Sara,
    imageMobile: Sara,
    voice: SarahVoice,
  },
  {
    id: 1,
    name: 'Megan',
    description:
      'Let’s practice English in a free-flowing exchange of thoughts',
    image: Ruby,
    imageMobile: Ruby,
    voice: RubyVoice,
  },
  {
    id: 2,
    name: 'Simon',
    description: 'Jump on a learning adventure full of laughter and creativity',
    image: Leo,
    imageMobile: Leo,
    voice: LeoVoice,
  },
  {
    id: 3,
    name: 'Thomas',
    description: 'Join me in a clear and methodical lesson',
    image: Henry,
    imageMobile: Henry,
    voice: HenryVoice,
  },
];

const Hero = () => {
  const [current, setCurrent] = useState(0);
  const { handleSendIPstats } = useContext(IpStatsContext);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 800;

  const handleChange = (e) => {
    setCurrent(() => e);
  };

  const handleVoice = () => {
    const audio = new Audio(CONFIG[current].voice);
    audio?.play();
  };

  return (
    <Wrapper>
      <div style={{ zIndex: '1' }}>
        <Desktop
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <MainText>Mów po angielsku bez stresu</MainText>
          <SubText>z Twoim osobistym Tutorem AI</SubText>
          <Description>
            Dostosuj naukę do swoich potrzeb. Poznaj Tutora AI, który będzie
            Twoim idealnym partnerem w rozmowie.
          </Description>
        </Desktop>
        <Mobile
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <MainText>Mów po angielsku</MainText>
          <SubText>z Twoim osobistym Tutorem AI</SubText>
        </Mobile>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '35px',
          }}
        >
          <ChoseTutorLinkMobile
            href="#plan"
            onClick={() => {
              handleSendIPstats(
                IP_STATS_ENUM.REGISTER_BY_MIDDLE,
                null,
                'LP_PRODUCT_1',
              );
              window.gtag('event', 'clicked_buy_now_hero');
            }}
          >
            ROZPOCZNIJ
          </ChoseTutorLinkMobile>
        </div>
      </div>
      <WrapperRight>
        <Carousel
          interval={2000}
          axis="horizontal"
          selectedItem={1}
          autoPlay={true}
          infiniteLoop={true}
          width={isMobile ? '380px' : '480px'}
          showThumbs={false}
          showIndicators={false}
          onChange={handleChange}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) => (
            <WrapperIcon
              onClick={onClickHandler}
              style={{
                left: '10px',
                cursor: 'pointer',
              }}
            >
              <img src={LeftArr} />
            </WrapperIcon>
          )}
          renderArrowNext={(onClickHandler, hasNext, label) => (
            <WrapperIcon
              onClick={onClickHandler}
              style={{
                right: '10px',
                cursor: 'pointer',
              }}
            >
              <img src={RightArr} />
            </WrapperIcon>
          )}
        >
          {CONFIG.map((c) => (
            <ElementCaruselWrapper>
              <ImageWrapper>
                <img src={c.image} style={{ zIndex: '2' }} />
              </ImageWrapper>
              <DescriptionWrapper style={{ zIndex: '10' }}>
                <NameWrapper>{c.name}</NameWrapper>
                <DescriptionText>{c.description}</DescriptionText>
              </DescriptionWrapper>
              <Voice onClick={() => handleVoice()}>
                <img src={Volume} />
              </Voice>
            </ElementCaruselWrapper>
          ))}
        </Carousel>
        <ChoseTutorLink href="#plan">ROZPOCZNIJ</ChoseTutorLink>
      </WrapperRight>
    </Wrapper>
  );
};

export default Hero;

import React, { useContext } from 'react';
import styled from 'styled-components';
import Logo from '../images/synthi-logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { getNavigationPathApp } from '@/common/navigation/getNavigationPathApp';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
`;

const LogoWrapper = styled.div`
  width: 130px;

  img {
    width: 100%;
    height: auto;
  }

  display: flex;
`;

const LoginButton = styled(Link)`
  color: #1e272f;
  font-feature-settings: 'salt' on, 'liga' off;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 185.714% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-decoration: none;
`;

const BuyButton = styled(Link)`
  color: #730bdb;
  font-feature-settings: 'salt' on, 'liga' off;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 185.714% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-decoration: none;
`;

const LinksWrapper = styled.div`
  margin-top: 6px;
  display: flex;
  gap: 46px;
`;

const Navbar = () => {
  const navigate = useNavigate();

  const { handleSendIPstats } = useContext(IpStatsContext);
  return (
    <Wrapper>
      <LogoWrapper>
        <img
          src={Logo}
          onClick={() => {
            navigate('/');
          }}
        />
      </LogoWrapper>
      <LinksWrapper>
        <LoginButton
          onClick={() => {
            handleSendIPstats(IP_STATS_ENUM.LOGIN, null, 'LP_PRODUCT_1');
            window.gtag('event', 'clicked_login_top_landing');
            window.location.assign(getNavigationPathApp('login'));
          }}
        >
          Zaloguj
        </LoginButton>
        <BuyButton
          onClick={() => {
            handleSendIPstats(
              IP_STATS_ENUM.REGISTER_BY_TOP,
              null,
              'LP_PRODUCT_1',
            );
            window.gtag('event', 'clicked_buy_on_top_landing');
            window.location.assign(getNavigationPathApp('register'));
          }}
        >
          Kup
        </BuyButton>
      </LinksWrapper>
    </Wrapper>
  );
};

export default Navbar;

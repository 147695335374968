import { styled } from "styled-components";
import { MessageWrapper, SectionWrapper } from "../../styles/wrappers";

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 20px;
  height: 300px;

  @media (max-width: 600px) {
    gap: 10px;
    height: calc(var(--vh, 1vh) * 74);
  }
`;

export const Logo = styled.img`
  margin-top: 0px;
  width: 47px;
  height: 47px;

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  font-family: Lato;
  gap: 20px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
`;

export const SomeTitle = styled.span`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;

  span {
    color: #000;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.6px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  margin-top: 100px;

  @media (max-width: 600px) {
    margin-top: -100px;
  }
`;

export const CustomedButton = styled.button`
  display: inline-flex;
  padding: 12px 30px;
  align-items: center;
  gap: 27px;
  border-radius: 50px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  color: white;
  border: none;

  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.28px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    padding: 13px 22px;
    align-items: center;
    gap: 27px;
  }
`;

export const Image = styled.img`
  width: 46px;
  height: 46px;
`;

export const DemoSummariazeWrapper = styled(SectionWrapper)`
  @media (max-width: 600px) {
    margin-top: 0px;
    min-width: 300px;
    height: calc(var(--vh, 1vh) * 76);
    max-width: 350px;
    padding: 10px 5px 5px 10px;
  }
`;

export const DemoMessageWrapper = styled(MessageWrapper)`
  max-width: 480px;
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
    padding: 10px;
    max-width: 200px;
  }
`;

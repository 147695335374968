import Demo from '@/pages/demo/DemoPage';
import Layout from '../components/layout/Layout';
import LP from '@/pages/lp/LpPage';
import Layout2 from '../components/layout/Layout2';
import HomePage from '@/pages/home/HomePage';

const routes = [
  {
    path: '/demo',
    element: (
      <Layout>
        <Demo />
      </Layout>
    ),
  },
  {
    path: '/lp',
    element: <LP />,
  },
  {
    path: '/',
    element: <HomePage />,
  },
];

export default routes;

import React, { useState } from 'react';
import styled from 'styled-components';
import OpenIcon from '../images/faq/open-icon.svg';
import CloseIcon from '../images/faq/close-icon.svg';

const ELEMENTS = [
  {
    top: 'Czym jest SynthiAi?',
    bottom: `SynthiAi to innowacyjna aplikacja mobilna, która uczy angielskiego za pomocą sztucznej inteligencji. Oferuje interaktywne rozmowy z chatbotem AI, pomagając uczącym się poprawić swoje umiejętności języka angielskiego.     `,
  },
  {
    top: 'Co sprawia, że nauka z SynthiAi jest skuteczna?',
    bottom: `SynthiAi wykorzystuje zaawansowane algorytmy przetwarzania języka naturalnego i uczenia maszynowego, aby angażować użytkowników w realistyczne rozmowy po angielsku. Inteligentny Asystent SynthiAi odpowiada na wiadomości użytkowników, udzielając opinii i sugestii poprawy.
                    `,
  },
  {
    top: 'Czy SynthiAi jest odpowiedni dla początkujących użytkowników?',
    bottom: `SynthiAi został zaprojektowany tak, aby odpowiadać uczniom wszystkich poziomów, od początkujących do zaawansowanych użytkowników. Dostosowuje trudność rozmowy w zależności od poziomu biegłości użytkownika, zapewniając optymalne doświadczenie nauki.
                    `,
  },
  {
    top: 'O czym mam rozmawiać z SynthiAi?',
    bottom: `SynthiAi sam poprowadzi cię przez tematy, które uznasz za interesujące i zajmie się prowadzeniem rozmowy. Inteligentny Asystent oferuje podpowiedzi jak rozpocząć wypowiedź, by wspomóc cię w płynnej rozmowie. 
                    `,
  },
  {
    top: 'Czy SynthiAi mówi po polsku?',
    bottom: `SynthiAi skupia się na nauczaniu języka angielskiego i prowadzi konwersacje po angielsku, ale rozumie też język polski. Dzięki temu zawsze możesz sprawdzić tłumaczenie wypowiedzi Inteligentnego Asystenta, a nawet odpowiedzieć po polsku.
                    `,
  },
  {
    top: 'Gdzie mogę korzystać z SynthiAi?',
    bottom: `Z SynthiAi możesz korzystać na dowolnym urządzeniu (smartfon, tablet, laptop) podłączonym do internetu, w dowolnej przeglądarce internetowej. 
              `,
  },
  {
    top: 'Czy mogę używać SynthiAi w trybie offline?',
    bottom: `Nie, SynthiAi działa online, ponieważ opiera się na algorytmach AI i połączeniu z serwerem. 
              `,
  },
  {
    top: 'Czy mogę ćwiczyć mówienie z SynthiAi w czasie rzeczywistym?',
    bottom: `Tak, SynthiAi umożliwia praktykę mówienia w czasie rzeczywistym za pośrednictwem chatbota AI. Możesz prowadzić mówione rozmowy i otrzymywać natychmiastową opinię w celu poprawy umiejętności mówienia.
              `,
  },
  {
    top: 'Jakie są dostępne metody płatności?',
    bottom: `Dla Twojej wygody akceptujemy szeroki zakres metod płatności, w tym szybkie przelewy bankowe, BLIK oraz wszystkie główne karty płatnicze. Wybierz metodę, która jest dla Ciebie najwygodniejsza, i zacznij swoją przygodę z językiem angielskim z SynthiAi! 
              `,
  },
  {
    top: 'Jak mogę zapłacić za SynthiAi i jakie są dostępne plany?',
    bottom: `W SynthiAi oferujemy proste i wygodne plany jednorazowej płatności, które pozwalają na pełny dostęp do aplikacji na okres 1, 3 lub 6 miesięcy. Po dokonaniu płatności, cieszysz się nieprzerwanym dostępem do wszystkich funkcji aplikacji przez wybrany czas, bez obaw o miesięczne opłaty. Oto nasze plany:
              `,
    plans: [
      '- Plan Basic: Idealny dla początkujących, oferujący kompletny dostęp na 1 miesiąc.',
      '- Plan Pro: Nasz najpopularniejszy plan, obejmujący 3 miesiące nauki z dodatkowymi funkcjami.',
      '- Plan Premium: Dla najbardziej zaangażowanych uczniów, zapewniający 6 miesięcy intensywnego rozwoju językowego.',
      'Po upływie tego okresu, możesz łatwo odnowić swój plan, aby kontynuować naukę bez przerwy.',
    ],
  },
];

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const Title = styled.h3`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  margin: 0;
  margin-top: 42px;
  margin-bottom: 24px;

  @media (min-width: 600px) {
    color: #29185e;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
  }
`;

const FaqsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1200px;
  border-radius: 12px;
  background: #f4f6f8;
  padding: 10px 2px;
  margin: 10px 20px;
  z-index: 5;

  @media (min-width: 600px) {
    width: 1000px;
  }
`;

const TitleFaq = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const DescriptionFaq = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 4px;
`;

const Element = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  gap: 12px;
`;

const Border = styled.div`
  width: 310px;
  height: 1px;
  background: #ece5f2;

  margin: 12px 0;

  @media (min-width: 600px) {
    width: 950px;
  }
`;

const IconWrapper = styled.div`
  margin: 3px;
`;

const InfoWrapper = styled.div`
  padding-right: 20px;
`;

const Faq = () => {
  const [openFaq, setOpenFaq] = useState();

  return (
    <Wrapper>
      <Title>FAQ</Title>
      <FaqsWrapper>
        {ELEMENTS.map((e, i) => (
          <>
            <Element
              onClick={() => setOpenFaq((prev) => (prev === i ? null : i))}
            >
              <IconWrapper>
                <img src={openFaq === i ? CloseIcon : OpenIcon} />
              </IconWrapper>
              <InfoWrapper>
                <TitleFaq>{e.top}</TitleFaq>
                {openFaq === i && <DescriptionFaq>{e.bottom}</DescriptionFaq>}
              </InfoWrapper>
            </Element>
            <Border />
          </>
        ))}
      </FaqsWrapper>
    </Wrapper>
  );
};

export default Faq;

import React, { useRef } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import Email from '../images/newsletter/email.svg';

const Wrpper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 42px 0 0 0;
  max-width: 320px;

  @media (min-width: 600px) {
    max-width: 520px;

    color: #29185e;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const Description = styled.p`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 330px;
  margin: 12px 0 0 0;

  @media (min-width: 600px) {
    max-width: 600px;
  }
`;
const Form = styled.form`
  margin-top: 24px;
  border-radius: 12px;

  width: 290px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  margin-top: 5px;
  width: 280px;
  height: 24px;
  padding: 8px 5px 8px 40px;
  border-radius: 8px;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 8px;
  background: #ece5f2;

  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */

  &:focus {
    outline: none;
    border: none;
  }
`;

const Label = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  text-align: left;
  width: 95%;
`;

const SaveButton = styled.button`
  display: inline-flex;
  padding: 15px 30px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  text-transform: uppercase;

  margin: 24px auto -24px auto;

  border-radius: 36px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  border: none;
  outline: none;
`;

const Color = styled.span`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const NewsLetter = () => {
  const emailRef = useRef();

  const newsLetterMutation = useMutation({
    mutationFn: () => {},
    onSuccess: () => {},
  });

  const handleNewsLetter = (e) => {
    e?.preventDefault();

    const payload = {
      email: emailRef.current.value,
    };

    newsLetterMutation.mutate(payload);
  };

  return (
    <Wrpper>
      <Title>Bądź na bieżąco i otrzymaj do 10% Rabatu!</Title>
      <Description>
        Zapisz się do newslettera i zgarnij do <Color>10% zniżki </Color> na
        pierwszą subskrypcję oraz ekskluzywne wskazówki i oferty od Synthi AI!
      </Description>
      <Form onSubmit={handleNewsLetter}>
        <div style={{ position: 'relative' }}>
          <img
            style={{ position: 'absolute', top: '18px', left: '10px' }}
            src={Email}
          />
          <Input
            required
            ref={emailRef}
            placeholder="example@synthiapp.com"
            type="email"
          />
        </div>
        <div></div>
        <SaveButton type="submit">Dołącz</SaveButton>
      </Form>
    </Wrpper>
  );
};

export default NewsLetter;

import React from 'react';
import styled from 'styled-components';
import Star from '../images/opinions/star.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 42px;

  position: relative;
`;

const Title = styled.h2`
  color: #29185e;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  z-index: 2;

  @media (min-width: 600px) {
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const Satisfaction = styled.h3`
  color: #730bdb;
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
`;

const StarsConfigWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 28px;
`;

const SmallText = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: -12px 0 0 0;
`;

const OpinionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100vw;
  margin-top: 24px;
  overflow-x: auto;
  padding-left: 30px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  z-index: 2;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 600px) {
    max-width: 1000px;
    justify-content: center;
    flex-wrap: wrap;
    margin: 40px auto;

    ::-webkit-scrollbar {
      display: unset;
    }
  }
`;

const Opinion = styled.div`
  width: 260px;
  height: 140px;
  flex-shrink: 0;

  padding: 18px;
  border-radius: 8px;
  background: #f4f6f8;
`;

const NameBox = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TopBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleBox = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 16px;
`;

const BoxDescription = styled.div`
  color: #29185e;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

const STARS_CONFIG = [Star, Star, Star, Star, Star];

const OPINIONS = [
  {
    name: 'Gosia',
    title: 'Zmieniła moje podejście do nauki',
    opinion:
      'Przed SynthiAI, nauka języka wydawała się niekończącą się walką. Teraz, dzięki interaktywnym rozmowom, każda lekcja to przyjemność i wyzwanie, które chcę podejmować każdego dnia.',
    stars: [Star, Star, Star, Star, Star],
  },
  {
    name: 'Szymon',
    title: 'Rewolucja w nauce angielskiego',
    opinion:
      'Nigdy nie myślałem, że AI może być tak skutecznym nauczycielem. SynthiAI nie tylko pomogło mi poprawić płynność mówienia, ale również zbudować pewność siebie w codziennych konwersacjach.',
    stars: [Star, Star, Star, Star, Star],
  },
  {
    name: 'Kasia',
    title: 'SynthiAI to przyszłość edukacji',
    opinion:
      'SynthiAI zmieniło sposób, w jaki myślę o nauce online. Symulacje życiowe i natychmiastowe podpowiedzi sprawiają, że każda sesja jest nie tylko pouczająca, ale też niesamowicie angażująca.',
    stars: [Star, Star, Star, Star, Star],
  },
  {
    name: 'Michał',
    title: 'Nauka języka stała się łatwiejsza',
    opinion:
      'Dzięki SynthiAI, przestałem obawiać się trudnych słów i zawiłych gramatycznych struktur. Aplikacja sprawiła, że nauka stała się naturalna i intuicyjna.    ',
    stars: [Star, Star, Star, Star, Star],
  },
  {
    name: 'Marcin',
    title: 'Szybkie postępy bez stresu',
    opinion:
      'Z SyntjiAI poczyniłem więcej postępów w ciągu kilku miesięcy niż przez ostatnie lata. To niesamowite, jak efektywne i bezstresowe może być uczenie się z odpowiednimi narzędziami.',
    stars: [Star, Star, Star, Star, Star],
  },
];

const TopInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const MobileHidden = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const VisibleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Opinions = () => {
  return (
    <Wrapper>
      <Title>Poznaj nasze opinie</Title>
      <TopInfoWrapper>
        <MobileHidden style={{ maxWidth: '350px', textAlign: 'center' }}>
          “To jak korzystanie z usług wyjątkowo skutecznego, osobistego mentora
          językowego”
        </MobileHidden>
        <VisibleBox>
          <StarsConfigWrapper>
            {STARS_CONFIG.map((sc) => (
              <img src={sc} />
            ))}
          </StarsConfigWrapper>
          <Satisfaction>99%</Satisfaction>
          <SmallText>zadowolonych klientów</SmallText>
        </VisibleBox>
      </TopInfoWrapper>
      <OpinionsWrapper>
        {OPINIONS.map((o) => (
          <Opinion>
            <TopBoxWrapper>
              <StarsConfigWrapper style={{ margin: '0' }}>
                {o.stars.map((sc) => (
                  <img src={sc} />
                ))}
              </StarsConfigWrapper>
              <NameBox>{o.name}</NameBox>
            </TopBoxWrapper>
            <TitleBox>{o.title}</TitleBox>
            <BoxDescription>{o.opinion}</BoxDescription>
          </Opinion>
        ))}
      </OpinionsWrapper>
    </Wrapper>
  );
};

export default Opinions;

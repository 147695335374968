import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import CookieContextProvider from './common/contexts/CookieContext';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <CookieContextProvider>
        <App />
      </CookieContextProvider>
    </QueryClientProvider>
  </BrowserRouter>,
);

export const getNavigationPathApp = (path) => {
  const uid = localStorage.getItem('uid');
  const utm_source = localStorage.getItem('utm_source');
  const uidutm_medium = localStorage.getItem('utm_medium');
  const utm_campaign = localStorage.getItem('utm_campaign');
  const utm_content = localStorage.getItem('utm_content');
  const fbclid = localStorage.getItem('fbclid');
  const pageName = localStorage.getItem('pageName');
  const msclkid = localStorage.getItem('msclkid');

  return `https://pl.app.synthiaiapp.com/${path}?uid=${uid}&utm_source=${utm_source}&utm_medium=${uidutm_medium}&utm_campaign=${utm_campaign}&utm_content=${utm_content}&fbclid=${fbclid}&pageName=${pageName}&msclkid=${msclkid}`;
};
